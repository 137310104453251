import {
	Box,
	Link,
	Avatar,
	Button,
	Icon,
	Typography,
	useMediaQuery,
	CircularProgress,
} from 'components/common';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { CommunityMember, Identity, Event } from 'core/types';
import { useTranslation } from 'hooks';
import { createImageServerUrl } from 'utils/url';
import { identityNameStyle } from './styles';

export type VolunteerDialogContentProps = {
	event: Event | undefined;
	members: CommunityMember[];
	onVolunteer?: (identity: Identity) => void;
	onUnVolunteer?: (identity: Identity) => void;
	isIdentityVolunteer: (identity: Identity) => boolean;
	disableActions?: boolean;
};

export const VolunteerDialogContent = (props: VolunteerDialogContentProps) => {
	const {
		event,
		members,
		isIdentityVolunteer,
		onVolunteer,
		onUnVolunteer,
		disableActions,
	} = props;

	const { t } = useTranslation();

	if (!event) return null;

	const isAllowedToVolunteer = (event.volunteers_available ?? 0) > 0;

	const renderAction = (identity: Identity) => {
		if (!isAllowedToVolunteer) {
			if (!isIdentityVolunteer(identity)) return null;

			return (
				<Button
					size='small'
					color='error'
					startIcon={<Icon name='cancel' />}
					onClick={() => onUnVolunteer?.(identity)}
					disabled={disableActions}
				>
					{t('event.remove')}
				</Button>
			);
		}

		if (isIdentityVolunteer(identity)) {
			return (
				<Button
					size='small'
					color='error'
					startIcon={<Icon name='cancel' />}
					onClick={() => onUnVolunteer?.(identity)}
					disabled={disableActions}
				>
					{t('event.remove')}
				</Button>
			);
		}

		return (
			<Button
				size='small'
				startIcon={<Icon name='add_circle' />}
				onClick={() => onVolunteer?.(identity)}
				disabled={disableActions}
			>
				{t('event.assign')}
			</Button>
		);
	};

	const renderMobileAction = (identity: Identity) => {
		if (!isAllowedToVolunteer) {
			if (!isIdentityVolunteer(identity)) return null;

			return (
				<Box onClick={() => onUnVolunteer?.(identity)}>
					<Icon fontSize='large' color='error' name='cancel' />
				</Box>
			);
		}

		if (isIdentityVolunteer(identity)) {
			return (
				<Box onClick={() => onUnVolunteer?.(identity)}>
					<Icon fontSize='large' color='error' name='cancel' />
				</Box>
			);
		}
		return (
			<Box onClick={() => onVolunteer?.(identity)}>
				<Icon fontSize='large' color='primary' name='add_circle' />
			</Box>
		);
	};

	const isExtraSmall = useMediaQuery.down('sm');

	return (
		<Box>
			<List>
				{members.length === 0 && (
					<CircularProgress message={t('common.loading')} />
				)}
				{members.map(member => {
					const { identity } = member;
					const avatarImageUrl = createImageServerUrl(
						identity?.image?.url ?? '',
						{
							w: 80,
							h: 80,
							fit: 'max',
						}
					);

					return (
						<ListItem
							key={identity.uuid}
							secondaryAction={
								isExtraSmall
									? renderMobileAction(identity)
									: renderAction(identity)
							}
						>
							<ListItemAvatar>
								<Avatar
									alt={`${identity?.first_name?.[0]} ${identity?.last_name?.[0]}`}
									src={avatarImageUrl}
								/>
							</ListItemAvatar>
							<ListItemText
								primary={`${identity.first_name} ${identity.last_name}`}
								primaryTypographyProps={{
									variant: 'body2',
									sx: identityNameStyle,
								}}
								secondary={
									<>
										<Typography variant='body1'>
											{identity?.location}
										</Typography>
										{identity.phone && (
											<Typography variant='body1'>
												<Link
													href={`tel:${identity.phone}`}
													sx={{ display: 'block' }}
												>
													{identity.phone}
												</Link>
											</Typography>
										)}
										<Typography variant='body1'>
											<Link
												href={`mailto:${identity.email}`}
											>
												{identity.email}
											</Link>
										</Typography>
									</>
								}
								secondaryTypographyProps={{
									variant: 'body1',
									mt: 0.5,
								}}
							/>
						</ListItem>
					);
				})}
			</List>
		</Box>
	);
};
