import {
	Avatar,
	Box,
	CardMenuItem,
	Grid,
	Link,
	Paper,
	Typography,
	useCardMenu,
} from 'components/common';
import { useTranslation, useVerifyAccess } from 'hooks';
import { CommunityMember, Identity } from 'core/types';
import { useCallback } from 'react';
import { SxProps } from '@mui/system';
import { createImageServerUrl } from 'utils/url';
import {
	activityStyles,
	avatarPositionStyle,
	borderStyle,
	groupStyle,
	nameStyle,
	overflowStyle,
	volunteerPreferencesBoxStyle,
	volunteerPreferenceStyles,
} from './styles';

export type CommunityMemberCardProps = {
	member: CommunityMember;
	hasInitiatedATransfer: boolean;
	checkIsOwner: (identity: Identity) => boolean;
	checkIsCurrentMember: (identity: Identity) => boolean;
	onRemoveMember?: (member: Identity) => void;
	onTransferMember?: (member: Identity) => void;
	onCancelTransfer?: () => void;
	onClickMember?: (identity: Identity) => void;
};

export const CommunityMemberCard = ({
	member,
	onRemoveMember,
	onTransferMember,
	onCancelTransfer,
	hasInitiatedATransfer,
	checkIsOwner,
	checkIsCurrentMember,
	onClickMember,
}: CommunityMemberCardProps) => {
	const { t } = useTranslation();

	const fullName = `${member.identity.first_name} ${member.identity.last_name}`;

	const isOwner = checkIsOwner(member.identity);
	const isCurrentMember = checkIsCurrentMember(member.identity);
	const canRemoveMember = useVerifyAccess()('delete', 'member');

	const handleOnTransfer = useCallback(() => {
		onTransferMember?.(member.identity);
	}, [onTransferMember, member]);

	const handleOnCancelTransfer = useCallback(() => {
		onCancelTransfer?.();
	}, [onCancelTransfer]);

	const handleOnRemoveMember = useCallback(() => {
		onRemoveMember?.(member.identity);
	}, [onRemoveMember, member]);

	const menuActions: CardMenuItem[] = [];

	if (isCurrentMember) {
		if (isOwner) {
			if (hasInitiatedATransfer) {
				menuActions.push({
					label: t('feed-card-menu.cancel-transfer'),
					icon: 'transfer_within_a_station',
					onClick: handleOnCancelTransfer,
					variant: 'error',
				});
			} else {
				menuActions.push({
					label: t('feed-card-menu.transfer-ownership'),
					icon: 'transfer_within_a_station',
					onClick: handleOnTransfer,
				});
			}
		} else {
			menuActions.push({
				label: t('feed-card-menu.leave-community'),
				icon: 'person_remove',
				onClick: handleOnRemoveMember,
			});
		}
	} else {
		if (canRemoveMember && !isOwner) {
			menuActions.push({
				label: t('feed-card-menu.remove-member'),
				icon: 'person_remove',
				onClick: handleOnRemoveMember,
			});
		}
	}

	const menuAction = useCardMenu(menuActions, { sx: { padding: 0 } });

	let groupList = member.groups;

	if (member.groups.length > 1) {
		groupList = member.groups.filter(group => group.name !== 'member');
	}

	const avatarImageUrl = createImageServerUrl(
		member?.identity?.image?.url ?? '',
		{
			w: 80,
			h: 80,
			fit: 'max',
		}
	);

	return (
		<Grid
			container
			mt={2}
			mb={2}
			px={3}
			py={3}
			component={Paper}
			elevation={0}
		>
			<Grid item md={3} sm={4} xs={6} display='flex'>
				<Box
					onClick={onClickMember?.bind(null, member.identity)}
					component={Avatar}
					sx={avatarPositionStyle}
					src={avatarImageUrl}
					alt={fullName}
				/>
				<Box
					ml={1.25}
					minWidth={0}
					sx={nameStyle}
					onClick={onClickMember?.bind(null, member.identity)}
				>
					<Typography
						textOverflow='ellipsis'
						overflow='hidden'
						variant='h6'
					>
						{fullName}
					</Typography>
					{groupList.map(group => (
						<Box key={group.uuid} sx={groupStyle}>
							{group.description}
						</Box>
					))}
				</Box>
			</Grid>
			<Grid item md={3} sm={4} xs={6} sx={borderStyle}>
				<Typography variant='body1'>
					{member.identity.location}
				</Typography>
				<Typography variant='body1'>{member.identity.phone}</Typography>
				<Typography variant='body1' sx={overflowStyle}>
					<Link href={`mailto:${member.identity.email}`}>
						{member.identity.email}
					</Link>
				</Typography>
			</Grid>
			<Grid
				item
				md={3}
				sm={4}
				sx={[borderStyle, activityStyles] as SxProps}
			>
				<Typography variant='body1'>
					{t('team.activity.tasks-created', {
						count: member.stats.all_time.tasks_created,
					})}
				</Typography>
				<Typography variant='body1'>
					{t('team.activity.tasks-completed', {
						count: member.stats.all_time.tasks_done,
					})}
				</Typography>
				<Typography variant='body1'>
					{t('team.activity.photos-uploaded', {
						count: member.stats.all_time.uploaded_photos,
					})}
				</Typography>
			</Grid>
			<Grid
				item
				md={3}
				sx={[borderStyle, volunteerPreferenceStyles] as SxProps}
			>
				<Grid container>
					<Grid item xs={11}>
						<Typography
							variant='body1'
							sx={volunteerPreferencesBoxStyle}
						>
							{member.help_provided.length > 0
								? member.help_provided
								: t('team.no-preferences')}
						</Typography>
					</Grid>
					<Grid item xs={1}>
						{menuAction && <Box>{menuAction}</Box>}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
